export default {
  items: [
    // {
    //   name: 'Dashboard',
    //   url: '/admin/',
    //   icon: 'icon-speedometer',
    // },
    {
      name: 'All Companies',
      url: '/admin/all-companies',
      icon: 'fa fa-building',
    },
    {
      name: 'WSS',
      url: '/admin/wss',
      icon: 'fa fa-users',
    },
    {
      name: 'Vendors',
      url: '/admin/vendor',
      icon: 'fa fa-users',
    },
    {
      name: 'Create Invoice',
      url: '/admin/create-invoice',
      icon: 'icon-layers',
    }
  ]
}
